import request from '@/utils/request'

const api = {
  // 获得列表页
  getListPage: '/approve/getListPage',
  // 同意
  agree: 'approve/agree',
  // 拒绝
  refuse: 'approve/refuse'
}

export default api

export function getListPage (data) {
  return request({
    url: api.getListPage,
    method: 'post',
    data
  })
}
export function agree (data) {
  return request({
    url: api.agree,
    method: 'post',
    data
  })
}
export function refuse (data) {
  return request({
    url: api.refuse,
    method: 'post',
    data
  })
}
