<template>
  <div class="sonfig-container">
    <div class="sonfig_right">
      <div class="adplace_left_search">
        <div class="pie-chart-container">
          <div class="pie-chart-container_con">
            <a-radio-group :disabled="loading" v-model="datetype" @change="callback">
              <a-radio-button value="a"> 待审核 </a-radio-button>
              <a-radio-button value="b"> 已审核 </a-radio-button>
            </a-radio-group>
            <div>
              <a-input-search
                placeholder="按名称查询"
                v-model="names"
                style="width: 200px; margin-right: 10px"
                @search="onSearch"
              />
              <a-button type="primary" @click="onSearch">搜索</a-button>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <a-table
            class="m_table_cust"
            :pagination="false"
            :columns="columns"
            :data-source="dataList"
            :rowKey="
              (record, index) => {
                return index
              }
            "
            :loading="loading"
            bordered
          >
            <template slot="action" slot-scope="text, record">
              <a-button size="small" @click="handleClickUpdatePlace(record)">审核</a-button>
            </template>
          </a-table>
          <a-pagination
            class="pagination"
            v-if="dataList.length > 0"
            show-size-changer
            :current="query.page"
            :show-total="(total) => `共 ${total} 条`"
            :total="total"
            @change="handleChangePage"
            @showSizeChange="onShowSizeChange"
          />
        </div>
        <m-empty v-if="!loading && dataList.length == 0" style="height: 70vh">
          <template #description>暂无数据</template>
        </m-empty>
      </div>
    </div>

    <Views
      v-if="addPlaceView"
      :placeView="placeView"
      :visible="addPlaceView"
      :isAdd="this.adverView"
      @modalCancel="addPlaceView = false"
      @changePlace="changePlace"
    />
  </div>
</template>

<script>
import { getListPage } from '@/api/auditManagement'
import Views from '../components/view'
// import mixDate from '@/mixins/initDate'
const columns = [
  {
    dataIndex: 'companyName',
    title: '公司名称',
    key: 'companyName',
    align: 'left'
  },
  {
    dataIndex: 'name',
    title: '名称',
    key: 'name',
    align: 'left'
  },
  {
    dataIndex: 'companyType ',
    title: '公司类型',
    key: 'companyType',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.companyType === '1') {
        text = '代理商'
      } else if (record.companyType === '2') {
        text = '广告主'
      }
      return text
    }
  },
  {
    dataIndex: 'agentName',
    title: '代理公司',
    key: 'agentName',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.agentName === '') {
        text = '--'
      }
      return text
    }
  },
  {
    dataIndex: 'username',
    title: '客户经理',
    key: 'username',
    align: 'left'
  },
  {
    dataIndex: 'tradeName',
    title: '行业',
    key: 'tradeName',
    align: 'left'
  },
  {
    dataIndex: 'advertiserCategory ',
    title: '广告分类',
    key: 'advertiserCategory',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.advertiserCategory === '1') {
        text = '品牌'
      } else if (record.advertiserCategory === '2') {
        text = '效果'
      }
      return text
    }
  },
  {
    dataIndex: 'approveSts ',
    title: '账号状态',
    key: 'approveSts',
    align: 'left',
    customRender: (text, record, index) => {
      if (record.approveSts === '0') {
        text = '待审核'
      } else if (record.approveSts === '1') {
        text = '审核通过'
      } else if (record.approveSts === '-1') {
        text = '审核不通过'
      }
      return text
    }
  },
  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left'
  }
]
export default {
  name: 'Audit',
  components: { Views },
  data () {
    return {
      names: '',
      datetype: 'a',
      advertisers: '',
      adverView: 'a',
      columns,
      addPlaceVisible: false,
      addPlaceView: false,
      placeInfo: {},
      supplier: 1,
      placeView: {},
      loading: false,
      addAbtestVisible: false,
      addGroupVisible: false,
      appList: [],
      placeList: [],
      industry: [],
      currentGroup: [],
      groupList: [],
      theme: 'light',
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      BusNames: { label: 'name', value: 'id' },
      pid: [],
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      businessUser: [],
      pidBus: [],
      dateStrings: [],
      dateString: [],
      manager: undefined,
      dataList: [],
      times: [],
      advertiserIndustry: '',
      businessUserPush: '',
      types: '',
      stsPush: 'uncheck'
    }
  },
  computed: {},
  mounted () {
    this.getGetListPage()
  },
  watch: {
    handler (val) {
      if (val.tradePid && val.tradeId) {
        this.pid = [val.tradePid, val.tradeId]
        this.query = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    callback (e) {
      this.adverView = e.target.value
      if (e.target.value === 'a') {
        this.stsPush = 'uncheck'
        this.getGetListPage()
      } else if (e.target.value === 'b') {
        this.stsPush = 'checked'
        this.getGetListPage()
      }
    },
    onSearch (value) {
      this.timer = setTimeout(() => {
        this.getGetListPage()
      }, 1000)
    },

    async getGetListPage () {
      this.loading = true
      this.dataList = []
      this.query = {
        page: 1,
        limit: 10,
        sts: this.stsPush,
        name: this.names
      }
      const res = await getListPage(this.query)
      if (res.code === 200) {
        this.dataList = res.data.list || []
        this.total = res.data.total
        setTimeout(() => {
          this.loading = false
        }, 200)
      }
    },

    // 审核
    handleClickUpdatePlace (record) {
      this.placeView = record
      this.addPlaceView = true
    },
    changeBusiness (val) {},

    changePlace (val) {
      this.getGetListPage()
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        this.getGetListPage()
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        this.getGetListPage()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.sonfig-container {
  padding: 0 10px;
  .sonfig_left {
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    min-height: 620px;
  }
  .ant-menu-item-selected {
    background-color: fade(@primary-color,  5%);
    &::after {
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      border-radius: 5px;
      border-left: 3px solid @primary-color;
      border-right: none;
    }
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .adplace_left_search {
    min-height: 90vh;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
    position: relative;
    height: 100%;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .pagination {
      padding-top: 20px;
    }
  }
  ::v-deep {
    .ant-empty-normal {
      display: none;
    }
  }
  .sonfig_date {
    margin-left: 20%;
    margin-right: 10px;
  }
  .sonfig_industry {
    margin-left: 10px;
    margin-right: 10px;
  }
  .sonfig_businessUser {
    margin-right: 10px;
  }
  .ant-btn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    touch-action: manipulation;
    height: 32px;
    font-size: 14px;
    border-radius: 5px;
  }
  .sonfig_right {
    flex-grow: 1;
  }
  .pie-chart-container {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 5px;
    .pie-chart-container_con {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .aRadioBtn();
    .control {
      width: 100%;
      padding: 0 5px;
      display: flex;
      justify-content: center;
    }
    /deep/.ant-spin-container {
      position: relative;
      height: 100%;
      .chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
