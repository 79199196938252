<template>
  <a-modal v-model="addPlaceView" :title="this.names" :closable="false">
    <a-form-model style="max-height: 55vh; overflow-y: auto" ref="ruleForm" :model="query" v-bind="layout">
      <a-form-model-item class="form_item_audit" has-feedback label="名称">
        {{ query.name }}
      </a-form-model-item>
      <a-form-model-item class="form_item_audit" has-feedback label="公司全称">
        {{ query.companyName }}
      </a-form-model-item>
      <a-form-model-item class="form_item_audit" has-feedback label="联系电话">
        {{ query.contactPhone }}
      </a-form-model-item>
      <a-form-model-item class="form_item_audit" has-feedback label="代理公司">
        {{ query.agentName }}
      </a-form-model-item>
      <a-form-model-item class="form_item_audit" has-feedback label="客户经理">
        {{ query.username }}
      </a-form-model-item>
      <a-form-model-item class="form_item_audit" has-feedback label="所属行业">
        {{ query.tradeName }}
      </a-form-model-item>
      <a-form-model-item class="form_item_audit" has-feedback label="广告分类">
        {{ query.advertiserCategory }}
      </a-form-model-item>
      <a-form-model-item class="form_item_audit" has-feedback label="审核人">
        {{ query.approveUserName }}
      </a-form-model-item>
      <a-form-model-item label="备注信息">
        <a-input v-model.trim="query.approveRemark" type="textarea" />
      </a-form-model-item>
      <a-form-model-item class="form_item_audit" has-feedback label="资质">
        <div class="image_item" v-for="item in this.imagesUrl" :key="item">
          <el-image
            v-if="item"
            style="max-height: 90px"
            :src="resourceUrl + item"
            :preview-src-list="[resourceUrl + item]"
            alt="资质"
          ></el-image>
        </div>
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button type="danger" @click="handleCancel" v-if="this.isAdd === 'a'"> 拒绝 </a-button>
      <a-button type="primary" style="margin-left: 10px" @click="handleSubmit" v-if="this.isAdd === 'a'">
        通过
      </a-button>
      <a-button type="danger" @click="handleDown" v-if="this.isAdd === 'b'"> 关闭 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { agree, refuse } from '@/api/auditManagement'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: [],
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      appList: [],
      positionList: [],
      templateList: [],
      names: '',
      companyTypes: ''
    }
  },
  props: {
    isAdd: {
      default: '',
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    appId: {
      default: '',
      type: String
    },
    placeView: {
      type: [Boolean, Object],
      default: () => ({})
    }
  },
  watch: {
    placeView: {
      handler (val) {
        this.names = '审批'
        this.query = JSON.parse(JSON.stringify(val))
        // this.imagesUrl = process.env.VUE_APP_API_BASE_URL + val.businessLicense

        if (val.advertiserCategory === '1') {
          this.query.advertiserCategory = '品牌'
        } else if (val.advertiserCategory === '2') {
          this.query.advertiserCategory = '效果'
        } else {
          this.query.advertiserCategory = ''
        }
        this.companyTypes = val.companyType
        if (!val.businessLicense) {
        } else {
          this.imagesUrl = val.businessLicense.split(',')
        }
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {},
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceView: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleDown () {
      this.addPlaceView = false
    },
    handleCancel () {
      this.addPlaceView = false
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.query = {
            id: this.query.id,
            companyType: this.query.companyType,
            approveRemark: this.query.approveRemark
          }
          const resp = await refuse(this.query)
          if (resp.code === 200) {
            this.$message.success('已拒绝!')
          }
          this.query = {}
          this.$emit('changePlace')
          this.addPlaceView = false
        } else {
          return false
        }
      })
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.query = {
            id: this.query.id,
            companyType: this.query.companyType,
            approveRemark: this.query.approveRemark
          }
          const resp = await agree(this.query)
          if (resp.code === 200) {
            this.$message.success('审批成功!')
          }
          this.query = {}
          this.$emit('changePlace')
          this.addPlaceView = false
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.form_item_audit {
  .image_item {
    width: 180px;
    height: 90px;
    border: 1px dashed #ccc;
    background: fade(@primary-color, 1%);
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    .el-image {
      display: flex;
      justify-content: center;
      // img {
      // }
    }
  }
}
</style>
